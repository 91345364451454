/* ========================================================================
 * Bootstrap Tourist
 * Copyright FFS 2019
 * @ IGreatlyDislikeJavascript on Github
 *
 * z indexes:
 *				1110 - backdrop (black bg)
 *				1111 - highlight & tour element ()set by js)
 *				1112 - prevent
 *				1113 - popover
 */

 .tour-backdrop {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*position: fixed;*/
  position: absolute;
  box-sizing: content-box;
  z-index: 1110;
  background-color: #000;
  opacity: 0.8;
  -ms-filter: "alpha(opacity=90)";
  filter: alpha(opacity=90);
}

.tour-backdrop-animation {
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.tour-prevent {
  position: absolute;
  z-index: 1112;
  opacity: 0.20;
  filter: alpha(opacity=20);
}

.tour-highlight {
  box-sizing: content-box;
  position: absolute;
  z-index: 1110;
  background-color: #FFF;
  background-color: rgba(255,255,255,.9);
}

.tour-highlight-animation {
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.tour-highlight-element {
  position: relative;
  z-index: 1111 !important;
}

.popover[class*="tour-"] {
  z-index: 1113;
}

.popover[class*="tour-"] .popover-navigation {
	padding: 9px 14px;
	overflow: hidden;
}

.popover[class*="tour-"] .popover-navigation *[data-role="end"] {
	float: right;
}

.popover[class*="tour-"] .popover-navigation *[data-role="prev"],
.popover[class*="tour-"] .popover-navigation *[data-role="next"],
.popover[class*="tour-"] .popover-navigation *[data-role="end"] {
	cursor: pointer;
}

.popover[class*="tour-"] .popover-navigation *[data-role="prev"].disabled,
.popover[class*="tour-"] .popover-navigation *[data-role="next"].disabled,
.popover[class*="tour-"] .popover-navigation *[data-role="end"].disabled {
	cursor: default;
}

.popover[class*="tour-"].orphan {
	position: fixed;
	margin-top: 0;
}

.popover[class*="tour-"].orphan .arrow {
	display: none;
}

.tour-behind {
    z-index: -1;
}

.tour-zindexFix {
    z-index: 1029;
}